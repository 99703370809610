/* purgecss start ignore */
@tailwind base;

@tailwind components;

.toast-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@layer components {
  .form-input {
    border-color: theme("colors.border-dark-gray");
    border-radius: 0.75rem;
    border-width: 1px;
    box-shadow: 0 0 1px theme("colors.border-dark-gray");
  }
  .form-input:focus {
    border-color: theme("colors.light-purple");
    outline: 0;

    box-shadow: 0 0 1px theme("colors.dark-purple");
  }
  .form-input:focus-within {
    border-color: theme("colors.light-purple");
    box-shadow: 0 0 1px theme("colors.dark-purple");
  }

  .pill-button {
    display: block;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;
    border: 2px solid theme("colors.light-purple");
  }

  .button-hover:hover {
    border-color: transparent;
    background-color: theme("colors.dark-purple");
    color: white;
  }

  .transition-package-toggle {
    transition: opacity 0.4s ease-in-out;
  }

  .animate-delete {
    transition: max-height 0.5s ease-out;
  }

  .animate-wind {
    animation-duration: 1s;
    animation-name: wind;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes wind {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .bounce-bird {
    animation-duration: 0.8s;
    animation-name: bounce-bird;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }

  @keyframes bounce-bird {
    from {
      transform: translateY(10px);
    }

    to {
      transform: translateY(-10px);
    }
  }

  .accordion-content {
    max-height: 0;
    -webkit-transition: max-height 0.35s ease-in-out;
    -o-transition: max-height 0.35s ease-in-out;
    transition: max-height 0.35s ease-in-out;
  }

  .youtubeContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
  }

  ::selection {
    background: theme("colors.disabled-purple");
  }

  .circle-progress {
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}

:focus-visible {
  outline: 2px solid theme("colors.light-purple");
}

.scene {
  position: relative;
}

.carousel {
  position: absolute;
}

.carousel__cell {
  position: absolute;
  background-color: white;
  box-shadow: 1.5px 5px 3.5px rgba(0, 0, 0, 0.25);
  top: 10px;
  border-radius: 10px;
  transition: transform 0.7s, opacity 0.3s;
  backface-visibility: hidden;
}

/* purgecss end ignore */
@tailwind utilities;

@layer base {
  * {
    @apply font-montserrat;
  }
}
